import PropTypes from "prop-types";
import React from "react";

import Footer from "./footer";

function LayoutNoHeader({ children, bgColor }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <main className="flex-1" style={{backgroundColor: bgColor}}>
        {children}
      </main>

      <Footer />
    </div>
  );
}

LayoutNoHeader.propTypes = {
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
};

LayoutNoHeader.defaultProps = {
  bgColor: '#F7F7F7'
}

export default LayoutNoHeader;
