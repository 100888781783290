import React, {useState} from 'react'

import LayoutNoHeader from '../components/layout-noheader'
import headerBg from '../images/homepage-header-bg.png'
import Logo from '../components/logo'
import actLogo from '../images/act-logo.png'
import iconZoom from '../images/zoom-in.svg'
import iconBarChart from '../images/bar-chart.svg'
import {useQuery, gql} from '@apollo/client'
import {DebounceInput} from 'react-debounce-input'
import {Link} from 'gatsby'
import Avatar from '../images/avatar-placeholder.png'
import SEO from '../components/seo'
import axios from "axios";

const config = require('../config/default');

const headerStyles = {
    backgroundImage: `url(${headerBg})`,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    backgroundSize: 'cover',
    height: '50vh',
    minHeight: 400,
}

function IndexPage() {
    const [state, setState] = useState({
        data: {
            allPeople: {
                edges: []
            }
        },
        loading: false,
        is_search: true,
        q: ''
    });

    let SEARCH_NAMES = function (search) {
        if (search === null) return []

        let query = `
            query($search: String) {
              allPeople(search: $search, first: 10) {
                edges {
                  node {
                    id
                    name
                    sortname
                    image
                    partytype
                  }
                }
              }

            }
          `.replace(/\n/g, ' ');

        axios.post(config.GRAPHQL_API_URL, {
            query: query,
            variables: {
                "search": search
            }
        })
            .then(function (response) {
                // console.log(response.data.data);
                setState({
                    "is_search": false,
                    "loading": false,
                    "q": search,
                    "data": response.data.data
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        setState({
            "is_search": false,
            "loading": true,
            "data": {
                "allPeople": {
                    "edges": []
                }
            },
            "q": search
        });


    }

    let loading = state.loading;
    let data = state.data;
    let q = state.q;

    return (
        <LayoutNoHeader>
            <SEO
                keywords={[`corrupt0`, `political`, `data`]}
                title="Corrupt0 Homepage"
            />
            <header className="">
                <div className="flex-1 w-full max-w-6xl mx-auto px-4 py-8 md:px-8 md:pb-16 md:pt-8">
                    <a href={"https://actai.co/"} >
                        <img className="absolute mt-0 ml-5 w-12 object-scale-down" src={actLogo} />
                    </a>
                    <Logo />
                    <div className="relative mt-16 md:w-3/4 lg:w-1/2 mx-auto">
                        <DebounceInput
                            tabIndex={0}
                            autoFocus={true}
                            debounceTimeout={500}
                            onChange={e => SEARCH_NAMES(e.target.value)}
                            className="appearance-none w-full border rounded border-gray-500  py-3 px-6 leading-tight focus:outline-none bg-white text-2xl font-weight-bold"
                            type="text" placeholder="Person name"
                        />
                        <button className="border-0 absolute right-0 mr-6 mt-5">
                            <img src={iconZoom}/>
                        </button>
                        {q ?
                            <ul className="list-none border absolute mt-16 p-1 top-0 w-full bg-white rounded overflow-y-auto"
                                style={{maxHeight: '30vh'}}>
                                {data && data.allPeople.edges.length > 0 ?
                                    data.allPeople.edges.map(item => (
                                        <li key={item.node.id}>
                                            <Link className="block hover:bg-yellow-400 rounded p-3 cursor-pointer"
                                                  to={item.node.partytype ==="PERSON"? `/person?id=${item.node.id}`: item.node.partytype ==="COMPANY"? `/company?id=${item.node.id}`: `/political-party?id=${item.node.id}`}>
                                                <div className="flex">
                                                    {item.node.image? <img src={item.node.image} alt="avatar image" className="rounded-full w-8 h-8 object-cover"/>:
                                                        <img src={Avatar} alt="avatar image" className="rounded-full w-8 h-8"/>
                                                    }
                                                    <div className="ml-4">{item.node.name}</div>
                                                </div>
                                            </Link>
                                        </li>
                                    )) :
                                    <li className="p-3 rounded bg-gray-100 text-gray-600 text-center italic">
                                        {loading ?
                                            <span>Loading &hellip;</span> :
                                            <span>No person matched query</span>
                                        }
                                    </li>}
                            </ul> : ''}
                    </div>
                </div>
            </header>

            <div className="flex-1 w-full max-w-6xl px-4 py-8 md:px-8 md:py-16 mx-auto">
                <div className="grid md:grid-rows-1 md:grid-cols-3 grid-cols-1 gap-8">
                    <div className="md:row-span-3 hidden">
                        <h3 className="font-bold text-2xl">ข้อมูลใหม่</h3>
                        <ul className="list-none">
                            <li className="mt-3 mb-1">
                                <div className="text-xl">บัญชีทรัพย์สิน
                                </div>
                                <div className="text-sm text-gray-600 mt-1 italic">12 May 2020</div>
                                <hr className="border-2 border-black w-1/6 mt-5"/>
                            </li>
                            <li className="mt-3 mb-1">
                                <div className="text-xl">คดีมีมูลความผิดของ ปปช.
                                </div>
                                <div className="text-sm text-gray-600 mt-1 italic">12 May 2020</div>
                                <hr className="border-2 border-black w-1/6 mt-5"/>
                            </li>
                        </ul>
                    </div>
                    <div className="col-span-3 lg:col-span-1">
                        <h3 className="font-bold text-2xl ">วิเคราะห์ข้อมูล</h3>
                        <ul className="list-none">
                            <li className="mt-3 mb-1 border-l-4 border-red-500 bg-white">
                                <a href="https://public.tableau.com/app/profile/tulyawat.mahawong3474/viz/Corruptzero-/sheet0" target="_blank" className="p-3 block"><span className="text-xl">วิเคราะห์ข้อมูลบัญชีทรัพย์สินผู้ดำรงตำแหน่งทางการเมือง</span></a>
                            </li>
                            <li className="mt-3 mb-1 border-l-4 border-red-500 bg-white">
                                <a href="https://public.tableau.com/app/profile/tulyawat.mahawong3474/viz/Corruptzero-_16327345360360/sheet5" target="_blank" className="p-3 block"><span className="text-xl">วิเคราะห์ข้อมูลการบริจาคให้พรรคการเมือง</span></a>
                            </li>
                            <li className="mt-3 mb-1 border-l-4 border-red-500 bg-white">
                                <a href="https://public.tableau.com/app/profile/tulyawat.mahawong3474/viz/corruptzero-__/Dashboard1" target="_blank" className="p-3 block"><span className="text-xl">วิเคราะห์ข้อมูลการบังคับใช้กฎหมายของ ก.ล.ต.</span></a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-span-3 lg:col-span-1">
                        <h3 className="font-bold text-2xl">ร่วม <span className="font-avenir"> Digitize</span></h3>
                        <ul className="list-none">
                            {/*<li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">*/}
                            {/*    <a href="https://datathon.corrupt0.org/nacc-case" target="_blank">*/}
                            {/*    <div className="flex flex-row content-center">*/}
                            {/*        <img src={iconBarChart} className="w-8 h-8 mr-4"/>*/}
                            {/*        <div className="text-xl">คำชี้มูลความผิดของ ป.ป.ช.</div>*/}
                            {/*    </div></a>*/}
                            {/*</li>*/}
                            {/*<li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">*/}
                            {/*    <a href="https://datathon.corrupt0.org/nacc" target="_blank">*/}
                            {/*    <div className="flex flex-row content-center">*/}
                            {/*        <img src={iconBarChart} className="w-8 h-8 mr-4"/>*/}
                            {/*        <div className="text-xl"> บัญชีทรัพย์สินนักการเมือง</div>*/}
                            {/*    </div></a>*/}
                            {/*</li>*/}
                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <div className="flex flex-row content-center">
                                    {/*<img src={iconBarChart} className="w-8 h-8 mr-4"/>*/}
                                    <div className="text-xl">ยังไม่พบข้อมูล</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-span-3 lg:col-span-1">
                        <h3 className="font-bold text-2xl"><span className="">ดาวน์โหลดข้อมูล</span></h3>
                        <ul className="list-none">
                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://drive.google.com/file/d/1y_jKC8K756BKxNpa9CgXNvmgckoEVKsZ/view?usp=drivesdk" target="_blank">
                                    <div className="content-center">
                                        <div className="text-xl">ข้อมูลพรรคการเมือง</div>
                                        <div className="text-sm">Last updated: April 17, 2020</div>
                                    </div></a>
                            </li>
                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://drive.google.com/file/d/1kZjkE6_BXa77rWHw6AFODMpiY_gD6InB/view?usp=drivesdk" target="_blank">
                                    <div className="content-center">
                                        <div className="text-xl">ผลการเลือกตั้งทั่วไป 24 มีนาคม 2562</div>
                                        <div className="text-sm">Last updated: April 17, 2020</div>
                                    </div></a>
                            </li>
                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://drive.google.com/file/d/1jpMe5cAF0A8avITImaVRU7WNL_ts-0pE/view?usp=drivesdk" target="_blank">
                                    <div className="content-center">
                                        <div className="text-xl">รายชื่อผู้สมัครรับเลือกตั้งทั่วไป 24 มีนาคม 2564</div>
                                        <div className="text-sm">Last updated: April 17, 2020</div>
                                    </div></a>
                            </li>



                            {/*<li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">*/}
                            {/*    <a href="https://docs.google.com/file/d/1FPt5Ll-JW63KsqDz_yaB8hPBtMke5wOQ/edit?usp=docslist_api&filetype=msexcel" target="_blank">*/}
                            {/*        <div className="content-center">*/}
                            {/*            <div className="text-xl">ชุดข้อมูลดิจิทัลคำชี้มูลความผิดของ ป.ป.ช. ที่เปิดเผยบนเว็บไซต์ของสำนักงาน ป.ป.ช.</div>*/}
                            {/*            <div className="text-sm">Last updated: September 3, 2021</div>*/}
                            {/*        </div></a>*/}
                            {/*</li>*/}
                            {/*<li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">*/}
                            {/*    <a href="https://drive.google.com/drive/folders/1pNGfWc0XafJDFl9ep-vJ_PM4Fo94Su4A" target="_blank">*/}
                            {/*        <div className="content-center">*/}
                            {/*            <div className="text-xl">ชุดข้อมูลดิจิทัลจากสำนักงานคณะกรรมการการเลือกตั้ง (กกต.) รายนามผู้บริจาคให้พรรคการเมือง</div>*/}
                            {/*            <div className="text-sm">Last updated: August 13, 2021</div>*/}
                            {/*        </div></a>*/}
                            {/*</li>*/}
                            {/*<li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">*/}
                            {/*    <a href="https://drive.google.com/drive/folders/1cezezitAWnSRD1VjH0HbbFKUWMwp-LL3" target="_blank">*/}
                            {/*        <div className="content-center">*/}
                            {/*            <div className="text-xl">ชุดข้อมูลดิจิทัลบัญชีทรัพย์สินและหนี้สินของผู้ดำรงตำแหน่งทางการเมืองที่เปิดเผยในปี พ.ศ. 2562 - 2563</div>*/}
                            {/*            <div className="text-sm">Last updated: July 31, 2021</div>*/}
                            {/*        </div></a>*/}
                            {/*</li>*/}

                            {/*<li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">*/}
                            {/*    <a href="https://docs.google.com/file/d/1VP0qxfQ2A-jICGrPrMdgT0ctx1uKUz4v/edit?usp=docslist_api&filetype=msexcel" target="_blank">*/}
                            {/*        <div className="content-center">*/}
                            {/*            <div className="text-xl">ชุดข้อมูลดิจิทัลการบังคับใช้กฎหมายของ ก.ล.ต. ตั้งแต่ พ.ศ. 2553 - 2562 ที่เปิดเผยบนเว็บไซต์ ของ ก.ล.ต.</div>*/}
                            {/*            <div className="text-sm">Last updated: September 27, 2021</div>*/}
                            {/*        </div></a>*/}
                            {/*</li>*/}
                            {/*<li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">*/}
                            {/*    <a href="https://docs.google.com/file/d/1Bvqd_vUetfh7tB2KnwtarYWgR3pYe-Hn/edit?usp=docslist_api&filetype=msexcel">*/}
                            {/*        <div className="content-center">*/}
                            {/*            <div className="text-xl">ชุดข้อมูลดิจิทัลคำพิพากษาศาลฎีกาแผนกคดีอาญาของผู้ดำรงตำแหน่งทางการเมือง ตั้งแต่ ปี พ.ศ. 2546</div>*/}
                            {/*            <div className="text-sm">Last updated: August 18, 2021</div>*/}
                            {/*        </div></a>*/}
                            {/*</li>*/}
                        </ul>
                        <p className="text-gray-500 text-sm text-right mt-2"><Link to={"/dataset"}> more >>> </Link></p>
                    </div>
                </div>

            </div>

        </LayoutNoHeader>
    )
}

export default IndexPage
